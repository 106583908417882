import React from 'react';
import { observer } from 'mobx-react';

import { Card, Typography } from 'antd';



import { cardHeaderStyle } from './util/Style';

const { Title, Paragraph } = Typography;

function Premise() {

    /**
     * An introduction to why we design this platform
     */
    const getPlatformIntro = () => {

        return (
            <Card
                headStyle={cardHeaderStyle}
                style={{ marginTop: "10px", marginBottom: "10px" }}
                title={<Title level={4}>Document Generation Monitor</Title>}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
                    <div style={{ width: "40%", marginRight: 10, textAlign: "left" }}>
                        <Paragraph >
                        This is a portal to monitor the document generation requests.
                        Of course you can download the documents if they are generated. 
                        </Paragraph>
                    </div>
           
                </div>
            </Card>
        )
    }

    return (
        <div>
            {getPlatformIntro()}
        </div>
    )

}

export default observer(Premise);