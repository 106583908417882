import { action, makeObservable, observable, computed, runInAction } from 'mobx';

import APIProxy from './APIProxy';
import LoginStore from './LoginStore';

import { isBlank } from './Util';

const blankCredentials = {
    email: '',
    token: '',
    role: '',
    username: '',
    id: '',
}

const INIT = 'init';
const PENDING = 'pending';
const INVALID = 'invalid';
const DONE = 'done';
const ERROR = 'error';

class AppStore {

    state = INIT;

    credentials = blankCredentials;

    currentComponent = null;

    apiProxy = new APIProxy();
    loginStore = new LoginStore({ apiProxy: this.apiProxy });
    menus = [];

    socketToken = null;

    constructor() {

        makeObservable(this, {
            state: observable,
            menus: observable,
            currentComponent: observable,
            credentials: observable,
            socketToken: observable,

            logout: action,
            authenticate: action,
            transitionTo: action,
            navigateTo: action,

            isLoading: computed,
            isError: computed,
            isInvalid: computed,
            isHead: computed,
            isMember: computed,
        });
    }

    get isLoading() {
        return this.state === PENDING;
    }

    get isError() {
        return this.state === ERROR;
    }

    get isInvalid() {
        return this.state === INVALID;
    }

    setSessionFromStorage = () => {
        const storedCredentials = localStorage.getItem('credentials')

        if (storedCredentials !== null) {
            this.credentials = JSON.parse(storedCredentials);
            this.updateContext();
        }
        else {
            this.credentials = blankCredentials;
            this.updateContext();
        }
    }


    authenticate = async (values) => {

        this.state = PENDING;
        this.credentials = blankCredentials;

        const result = await this.loginStore.authenticate(values);

        runInAction(() => {

            this.state = result.state;

            if (result.state !== DONE) {
                return;
            }

            const data = result.data;


            // this.credentials.email = data.userId;
            // this.credentials.token = data.userId;
            // this.credentials.role = data.role;
            // this.credentials.loginId = data.role;
            // this.credentials.username = data.name;
            // this.credentials.id = data.id;


            this.credentials.email = "admin"
            this.credentials.token = "a";
            this.credentials.role = "a";
            this.credentials.loginId = 'a';
            this.credentials.username = "admin";
            this.credentials.id = "a";

            this.apiProxy.updateCredentialHeaders(this.credentials);

            this.updateContext();
            this.persistCredentials();

        });
    }

    persistCredentials = () => {
        localStorage.setItem('credentials', JSON.stringify(this.credentials));
    }

    updateContext = () => {
        this.apiProxy.updateCredentialHeaders(this.credentials);
        this.resolveMenu();
        this.resolveLandingPage();
    }

    resolveMenu() {
        if (!this.isLoggedIn()) {
            this.menus = require('./menus/GuestMenu.json');
            return;
        }

        this.menus = require('./menus/jobManager.json');
    }

    resolveLandingPage() {
        for (let menu of this.menus) {
            if (menu.isLandingPage) {
                this.transitionTo(menu.key);
                return;
            }
        }
    }

    navigateTo(index) {

        const menu = this.menus[index];
        this.currentComponent = menu;
    }

    transitionTo(componentkeyName) {
        for (let menu of this.menus) {
            if (menu.key === componentkeyName) {
                this.currentComponent = menu;
                break;
            }
        }
    }

    isLoggedIn = () => {
        return !(isBlank(this.credentials.email) || isBlank(this.credentials.token))
    }

    logout = () => {
        localStorage.setItem('credentials', JSON.stringify(blankCredentials));
        window.location.reload();
    }

    setProxy = (apiProxy) => {
        this.apiProxy = apiProxy;
    }

    get isHead() {
        return this.credentials.role === "head";
    }

    get isMember() {
        return this.credentials.role === "member";
    }
}

export default AppStore;
