import { notification } from 'antd';
import moment from 'moment';

export function humanizeDate(rawDate) {
  const m = new moment(rawDate);
  return m.format('DD-MM-YYYY HH:mm:ss');
}
export function TimelineDate(rawDate) {
  if (!rawDate) return ""
  const m = new moment(rawDate);
  return m.format('YYYY-MM-DD');
}

export function inputSperator(string) {
  let namePattern = /^[a-zA-Z ]+$/;
  
  let emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  let mobilePattern = /^[0-9]{10}$/;

  if (string.match(namePattern)) {
      return "name";
  } else if (string.match(emailPattern)) {
      return "email";
  } else if (string.match(mobilePattern)) {
      return "number";
  } else {
      return "Not a name, email, or mobile number";
  }
}


export const openNotificationWithIcon = (type, title) => {
  notification[type]({ message: title });
}; 