import React, { Component } from "react";
import { observer } from "mobx-react";
import { AgGridReact } from "ag-grid-react";
import {  Row, Col, Typography, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { humanizeDate } from "../../util/Util";
import moment from 'moment'
const height = window.innerHeight * 0.65

const { Text, Link } = Typography;

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  flex: 1,
  minWidth: 180,
};


class JobList extends Component {
  constructor(props) {
    super(props);
    this.JobListStore = props.JobListStore;
    this.state = {
      jobInfo: {},
      setModal: false
    }
    this.gridApi = [];
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
  };

  handleState = (i) => {

    this.setState({ jobInfo: { ...i }, setModal: true });
  }

  JobAction = (info) => {

    return <>
      <EyeOutlined onClick={() => this.handleState(info.value)} />
    </>;
  };

  handleFetch = () => {
    this.JobListStore.fetchjobs();
  }
  componentDidMount() {
    this.handleFetch()
  }
  render() {
    let rowData = this.JobListStore.jobs;

    const columnDefs = [
      {
        field: "created_at",
        headerName: "Requested At",
        valueGetter: (params) => humanizeDate(params.data.created_at)

      },
      {
        field: "id",
        headerName: "Request ID",

      },
      {
        field: "status",
        headerName: "Status",

      },
      {
        field: "finished_at",
        headerName: "Finished At",
        valueGetter: (params) => humanizeDate(params.data.finished_at)

      }, {
        headerName: "Dur (sec)", cellStyle: { textAlign: 'right' }, maxWidth: '150',
        valueGetter: (params) => calculateDuration(params.data.created_at, params.data.finished_at)
      },
      {

        headerName: "Action", cellStyle: { textAlign: 'center' }, maxWidth: '120',
        cellRenderer: this.JobAction,
        valueGetter: (params) => params.data,
      },
    ];

    function calculateDuration(startDate, endDate) {
      startDate = new moment(startDate)
      endDate = new moment(endDate)
      const duration = moment.duration(endDate.diff(startDate));
      const formattedDuration = `${duration.seconds()}`;
      return formattedDuration;
    }

    return (
      <div className="ag-theme-alpine" style={{ height: height, width: "96%", margin: "auto" }}>
        <p> Requests:
          <span style={{ fontWeight: "600" }}>
          {" "+this.JobListStore.rowCount}
        </span>
        </p>
        <AgGridReact
          onRowClicked={this.onRowClick}
          rowHeight={40}
          headerHeight={35}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          suppressExcelExport={true}
          onGridReady={this.onGridReady}
          enableCellTextSelection={true}
        ></AgGridReact>

        <Modal open={this.state.setModal} onOk={() => this.setState({ setModal: false })} onCancel={() => this.setState({ setModal: false })} >

          <Row>
            <Col><Text>JOB ID - {this.state.jobInfo?.id} </Text></Col>
          </Row>
          <Row>
            {this.state.jobInfo?.urls?.split(";").map((e) => {
              return <Col>
                <Link href={`https://docgen.svasti.in/docgen/file/${e}`} >{e}</Link>
              </Col>
            })}
          </Row>
        </Modal>
      </div>
    );
  }
}

export default observer(JobList);

