import { authKey, backendHost } from "./APIEndpoints";

export default class APIProxy {
  authHeaders = {
    token: "",
    email: "",
    name: "",
    userFuzzyId: "",
  };

  getUserFuzzyId = () => {
    return this.authHeaders.userFuzzyId;
  };

  updateCredentialHeaders(credentials) {
    this.authHeaders = {
      token: credentials.token,
      email: credentials.email,
      name : credentials.username,
      userFuzzyId: credentials.id,
      loginId: credentials.loginId
    };
  }

  getAsync(url) {
    return fetch(backendHost + url)
  }
}