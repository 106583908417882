import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Login from './LoginUI';
import About from './About';

import JobStatusDashboardUI from './Job Manager/JobStatusDashboardUI';


class SelectedComponent extends Component {

    evaluateComponent() {
        const currentComponent = this.props.appStore.currentComponent;

        if (!currentComponent) {
            return <Login appStore={this.props.appStore} />;
        }
        switch (currentComponent.key) {
            case 'JSDashboard': {
                return <JobStatusDashboardUI appStore={this.props.appStore} />;
            }
            case 'login': {
                return <Login appStore={this.props.appStore} />;
            }
            case 'about': {
                return <About appStore={this.props.appStore} />;
            }
            default: {
                return <About appStore={this.props.appStore} />;
            }
        }
    }

    render() {
        return (this.evaluateComponent());
    }
}

export default observer(SelectedComponent);
