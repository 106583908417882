import React from 'react';
import { Menu } from 'antd';
import { observer } from 'mobx-react';
import { LogoutOutlined } from '@ant-design/icons';

import { headerMenuStyle } from './util/Style';

function AccountMenu(props) {

    return (
        <Menu style={headerMenuStyle}>
         
            <Menu.Item key="logout" onClick={props.appStore.logout} icon={<LogoutOutlined />}>
                Logout
            </Menu.Item>
        </Menu>
    );

}

export default observer(AccountMenu);