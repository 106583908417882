import React, { Component } from "react";
import { observer } from "mobx-react";

import { Tag, Space, Typography } from "antd";
import { HeatMapOutlined } from "@ant-design/icons";

import JobListStore from "./jobStatus/JobListStore";
import JobList from "./jobStatus/JobList";
import CriteriaForm from "./CriteriaForm";

const { Text } = Typography;

class JobStatusDashboardUI extends Component {
  constructor(props) {
    super(props);
    const apiProxy = props.appStore.apiProxy;
    this.JobListStore = new JobListStore(apiProxy);
  }

  fetchbookings = () => {
    this.JobListStore.fetchbookings(this.JobListStore.criteria);
  };

  countTag = () => {
    if (this.JobListStore.isDone) {
      return (
        <Space>
          <Tag icon={<HeatMapOutlined />} color="#1890ff">
            <Text level={5} style={{ color: "#F0F8FF" }}>
              {" "}
              {this.JobListStore.rowCount} Bookings
            </Text>
          </Tag>
        </Space>
      );
    }
    if (this.JobListStore.isError) {
      return <Tag color="red">...</Tag>;
    }
  };


  render() {

    return (
      <>
        <CriteriaForm
          appStore={this.props.appStore}
          JobListStore={this.JobListStore}
        />
        <JobList
          showTimeline={this.showTimeline}
          appStore={this.props.appStore}
          JobListStore={this.JobListStore}
        />
        ,
      </>
    );
  }
}

export default observer(JobStatusDashboardUI);
