
import { message } from 'antd';
import { makeObservable, observable, computed, action } from 'mobx';


const INIT = "init";
const PENDING = 'pending';
const INVALID = 'invalid';
const DONE = 'done';
const ERROR = 'error';

const EMPTY_MESSAGE = { status: "", help: "" };

export default class LoginStore {

    state = INIT;
    message = EMPTY_MESSAGE;

    constructor(props) {
        this.apiProxy = props.apiProxy

        makeObservable(this, {
            state: observable,
            message: observable,
            isLoading: computed,
            isDone: computed,
            isError: computed,
            authenticate: action,
        })
    }

    get isLoading() {
        return this.state === PENDING;
    }

    get isDone() {
        return this.state === DONE;
    }

    get isError() {
        return this.state === ERROR;
    }

    authenticate = async (values) => {
        const url = 'login';
        
        try {
            // const response = await this.apiProxy.post(url, {username:values.email,password:values.password});
            const response ={}
            const data = response;
            // if (response.message === "Success"  && response.response.role===11) {
             if(values.password==="FerRic)xide"&&values.email==="Anil"){
                 return { state: DONE, data: data.response };
             }
              
                 
             else {
                message.warning(response.message);
                return { state: INVALID, data: "" };
            }
        }
        catch {
            return { state: ERROR }
        }
    }
}