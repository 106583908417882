import React, { Component } from 'react';

import './App.css';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Layout } from 'antd';

import ToolBar from './components/ToolBar';
import SelectedComponent from './components/SelectedComponent'

import AppStore from './components/stores/AppStore';

const { Content, Footer } = Layout;

const minHeight = window.innerHeight * .89;

export default class App extends Component {

    constructor() {
        super();
        this.appStore = new AppStore();
    }

    renderLayout = () => {
        return (
            <>
                <ToolBar appStore={this.appStore} />
                <Content className="site-layout" style={{ padding: '0 0px', marginTop: 64 }}>
                    <div className="site-layout-background" style={{ padding: 2, minHeight: minHeight }}>
                        <SelectedComponent appStore={this.appStore} />
                    </div>
                </Content>
                <Footer style={{ padding: '0px 0px' }}></Footer>
            </>
        )
    }


    render() {
        return (
            <Layout>
                {this.renderLayout()}
            </Layout>
        )
    }
}
