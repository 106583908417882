import { makeObservable, observable, action, computed } from "mobx";
import {TimelineDate} from "../../util/Util";

const INIT = "init";
const PENDING = "pending";
const DONE = "done";
const ERROR = "error";
class JobListStore {
  jobs = [];

  rowCount = 0;
  criteria = {};
  selectedItems = [];
  state = INIT;
  constructor(apiProxy) {
    this.apiProxy = apiProxy;
    makeObservable(this, {
      rowCount: observable,
      jobs: observable,
      state: observable,

      fetchjobs: action,
      saveCriteria: action,
      isDone: computed,
    });
  }

  get isDone() {
    return this.state === DONE;
  }

  get isError() {
    return this.state === ERROR;
  }
  get isPending() {
    return this.state === PENDING;
  }
  setSelectedItems(items) {
    this.selectedItems = items;
  }

  async saveCriteria(criteria) {
    await this.fetchjobs(criteria);
  }

  fetchjobs = async (criteria) => {
    this.state = PENDING;
    this.criteria = criteria;
    const url = this.makeUrl(criteria);
    try {
      const response = await this.apiProxy.getAsync(url);
      const result = await response.json();
      let rowData = result;

      this.jobs = rowData;
   
      this.rowCount = this.jobs.length;
      this.state = DONE;
    } catch (e) {
      this.state = ERROR;
    }
    this.state = DONE;
  };

  makeUrl = (criteria) => {
    let fromDate
    let toDate 

    if(criteria===undefined){
      fromDate = TimelineDate(Date.now())
      toDate = TimelineDate(Date.now())
    }
    else{
       fromDate=TimelineDate(criteria.fromDate);
       toDate =TimelineDate(criteria.toDate);
    }
    

    return `requests/${fromDate}/${toDate}`;
  };
}
export default JobListStore;
