import React from 'react';
import { observer } from 'mobx-react';
import SignIn from './SignIn';
import { pageTitle } from './util/Style';

// const loginBackground = 'https://iqubeprod.tvsmotor.com/bsvi/loginBackground.png';

const Login = observer(({ appStore }) => {
  return (
    <div style={{ margin: 5 }}>
      <div style={{
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'space-between', 
          textAlign: 'center', 
          // background: `url(${loginBackground})`, 
          backgroundRepeat: 'no-repeat',
          backgroundSize: '102% 140%',
          backgroundPosition: '-.5rem 40%', 
          height: '90vh'
        }}>
        <div style={{ width: '50%', textAlign: 'center', marginTop: '1.25rem' }}>
          {pageTitle('')}
        </div>
        <div style={{ width: '45%', textAlign: 'left', marginTop: '2rem' }}>
          <SignIn appStore={appStore} />
        </div>
      </div>
    </div>
  );
});

export default Login;