import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Button, Form, Input, Card, Typography, notification, message } from 'antd';


const { Title } = Typography;

const loginCard = {
    marginTop: 5,
    backgroundColor : "transparent",
    border:"none"
};

const inputStyles = {
    border : "1px solid #e6d4ff",
   borderRadius : ".5rem",
   padding : ".4rem .3rem"
}


const failureNotification = () => {
    const args = {
        message: 'Service Unavailable',
        description:
            'We are very sorry that we are unable to serve you at this moment. Please try after some time.',
        duration: 0,
        type: 'error',
    };
    notification.open(args);
};

class SignIn extends Component {

    changePassword = () => {
        const params = { parentKey: "login" };
        this.props.appStore.currentComponent = { label: "Change Password", key: "passwordReset", params: params };
    }

    registration = () => {
        const params = { parentKey: "login" };
        this.props.appStore.currentComponent = { label: "Registration", key: "registration", params: params };
    };

    onFinish = async (values) => {
        const store = this.props.appStore;
        await store.authenticate(values);

        if (store.isError) {
            failureNotification();
        }

        if (store.isInvalid) {
            message.warning('Invalid Login Details.');
        }
    };
    render() {
        
        const store = this.props.appStore;

        return (
            <>
                <Card style={loginCard} title={<Title level={4}>Login</Title>}>
                    <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} wrapperCol={{span: 16}} >

                        <Form.Item name="email"
                            rules={[{ required: true, message: 'Please enter your registered email id.' }]}
                            label="USER NAME">
                            <Input style={inputStyles}/>
                        </Form.Item>

                        <Form.Item name="password"
                            rules={[{ required: true, message: 'Please provide your password.' }]}
                            label="PASSWORD">
                            <Input.Password style={inputStyles}/>
                        </Form.Item>

                        <Form.Item>
                            <Button  size='large' style={{fontWeight:"bold",width:'100%',borderRadius:'.7rem'}} type="primary" htmlType="submit" disabled={store.isLoading}>LOGIN</Button>
                        </Form.Item>


                    </Form>


                </Card>

            </>
        );
    }

    
}

export default observer(SignIn);