import React, { Component } from "react";
import { observer } from "mobx-react";

import {
  Button,
  Form,
  DatePicker,
  Col,
  Row,
  Alert,
} from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";


const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: { span: 48 },
    sm: { span: 48 },
  },
  wrapperCol: {
    xs: { span: 48 },
    sm: { span: 48 },
  },
  layout: "vertical",
};

const formLableStyle = {
  fontWeight: "500",
  letterSpacing: "1px",
};

class CriteriaForm extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.JobListStore = props.JobListStore;
    this.state = {
      showAleart: false,
    };
  }

  componentDidMount() {}

  onFinish = async (values) => {
    const criteria = {
      rangeDate: values.rangeDate,
    };
    criteria.fromDate = criteria.rangeDate
      ? criteria.rangeDate[0]?.format("YYYY/MM/DD")
      : null;
    criteria.toDate = criteria.rangeDate
      ? criteria.rangeDate[1].format("YYYY/MM/DD")
      : null;

    if (this.criteriaCheck(criteria)) {
      this.setState({ showAleart: false });
      await this.JobListStore.saveCriteria(criteria);
    } else {
      this.setState({ showAleart: true });
    }
  };

  criteriaCheck = (criteria) => {
    let flag = false;
    for (let key in criteria) {
      if (key === "dateType" || key === "rangeDate") {
        continue;
      }
      if (criteria[key]) {
        flag = true;
      }
    }
    return flag;
  };

  searchIcon = () => {
    if (this.JobListStore.isPending) {
      return <LoadingOutlined />;
    }
    return <SearchOutlined />;
  };

  render() {
    return (
      <div
        size="small"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "15px",
          position: "relative",
        }}
      >
        <Form {...formItemLayout} ref={this.formRef} onFinish={this.onFinish}>
          <Row gutter={20} justify={"end"} style={{ width: "100vw" }}>
            <Col>
              <Form.Item
                style={formLableStyle}
                name="rangeDate"
                label="Date Range"
              >
                <RangePicker style={{ border: "1px solid #eee1ff" }} />
              </Form.Item>
            </Col>

            <Col
              style={{ height: "2rem", marginTop: "1.8rem", padding: "0 1rem" }}
            >
              <Form.Item label="">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={false}
                  icon={this.searchIcon()}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row justify="center">
          {this.state.showAleart && (
            <Alert
              message="Please provied a valid date range."
              type="error"
              closable
              style={{ width: "fit-content" }}
              onClose={() => this.setState({ showAleart: false })}
            />
          )}
        </Row>
      </div>
    );
  }
}

export default observer(CriteriaForm);
