import React from 'react';
import { Card } from 'antd';
import { cardHeaderStyle } from "./util/Style";
import Premise from "./Premise";

const aboutStyle = {
    marginBottom: 5,
};


function About() {

    return (
        <>
            <Premise />
            <Card headStyle={cardHeaderStyle} style={aboutStyle} title="">
                <p>Version 0.2</p>
                <p>June-2023</p>
            </Card>
        </>
    )
}

export default About;