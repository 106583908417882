import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Dropdown, Menu, Layout, Typography, Row, Col, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import ownerLogo from '../images/brand.png';

import AccountMenu from './AccountMenu';

const { Text } = Typography;
const { Header } = Layout;

const caption = "Relationship Manager";

class ToolBar extends Component {

    handleEvent = (e) => {
        this.props.appStore.navigateTo(e.key);
    }

    renderRoleTabs = () => {
        const appStore = this.props.appStore;
        if (!appStore.isLoggedIn()) {
            return (
                <>
                    <Col span={20} style={{ textAlign: "center" }} >
                        <Text style={{ color: "rgb(69,49,28)",fontSize:'1.5rem' }} strong={true}><span style={{fontWeight:'700'}}>Document Generation Monitor</span></Text>
                    </Col>
                </>
            )
        }

        return (
            <Col span={13}>
                <Menu mode="horizontal" defaultSelectedKeys={['0']} >
                    {
                        appStore.menus.map((item, index) => (
                            <Menu.Item key={index} className="customclass" onClick={this.handleEvent} >{item.label}</Menu.Item>)
                        )
                    }
                </Menu>
            </Col>
        )
    }

    renderRightMenu = () => {
        const appStore = this.props.appStore;
        if (appStore.isLoggedIn()) {
            return (
                <Col span={6} style={{ textAlign: "right" }}>
                    <Space>
                        <Dropdown.Button overlay={<AccountMenu appStore={appStore} />} trigger={['click']} icon={<UserOutlined />}>
                            {appStore.credentials.username}
                        </Dropdown.Button>
                    </Space>
                </Col>
            )
        }
    }

    render() {
        return (
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%', background: '#fff', padding: "0px 5px 0px 5px",boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px" }}>
                <Row>
                    <Col style={{ paddingTop: 18 }} span={2}>
                        <img alt="Job Logo" src={ownerLogo} align="left" style={{marginTop:".10rem"}} width="75%" title={caption} />
                    </Col>
                    {this.renderRoleTabs()}
                    {this.renderRightMenu()}
                </Row>
            </Header>
        )
    }
}

export default observer(ToolBar);